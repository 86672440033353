<template>
  <div
    class="StoreOnline_tr"
    v-if="$route.path == '/StoreOnline_tr' || $route.path == '/goodsList_tr' || $route.path == '/couponActivities'|| $route.path == '/sweepActivities'"
  >
    <div class="banner">
      <div class="container banner_title">韬儒—{{ $route.name }}</div>
      <div v-if="$route.path == '/goodsList_tr'" class="allCemetery container">
        <screen @updateInfo="updateInfo"/>
      </div>
    </div>
    <div v-if="$route.path == '/StoreOnline_tr'">
      <div class="container">
        <div class="title">
          推荐商品
          <span @click="routeTo('goodsList_tr')"
            >全部商品 <i class="iconfont iconarrow-right"></i
          ></span>
        </div>
        <div class="recommendGoods">
          <div class="click_left" @click="click_right">
            <i class="iconfont iconarrow-left"></i>
          </div>
          <div class="click_right" @click="click_left">
            <i class="iconfont iconarrow-right"></i>
          </div>
          <div class="recommendGoodsList">
            <ul :style="`transform: translateX(-${285 * clickCount}px);`">
              <li
                v-for="(item, index) in list"
                :key="index"
                @click="goodsDetail(item)"
              >
                <img :src="item.goodsPic" />
                <div class="desc">
                  <div>{{ item.goodsName }}</div>
                  <div>
                    ¥{{ item.goodsDiscountPrice || item.goodsCommodityPrice }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="title">韬儒优选</div>
        <!-- 金牌店铺- 好物排行 -->
        <div class="goldStore" id="goldStore">
          <div class="goldStoreLeft">
            <p class="goldStoreTitle" @click="routeTo('/goldStoreO')">
              金牌店铺 <icon class="iconfont iconxiangyou1"></icon>
            </p>
            <div class="goldStoreLeftB">
              <div
                class="goldStoreLeftBox"
                v-for="(item, index) in data.businessData"
                :key="index"
                @click="routeClick(item)"
              >
                <p>{{ item.name }}</p>
                <div>
                  <img :src="item.logo" alt="" />
                  <div>
                    <img
                      v-if="item.goodsList[0]"
                      :src="item.goodsList[0].goodsPic"
                      alt=""
                    />
                    <img
                      v-if="item.goodsList[1]"
                      :src="item.goodsList[1] ? item.goodsList[1].goodsPic : ''"
                      :alt="item.goodsList[1].goodsName"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="goldStoreRight">
            <div class="goldStoreRightTop">
              <p class="goldStoreTitle" @click="routeTo('/rankingList')">
                好物排行 <icon class="iconfont iconxiangyou1"></icon>
              </p>
              <div>
                <div
                  :style="
                    goodsId == index &&
                    'font-weight: 600;color: #299654; border-bottom:2px solid #299654;'
                  "
                  v-for="(item, index) in goodsItem"
                  :key="index"
                  @click="goodsIdFun(index)"
                >
                  {{ item }}
                </div>
              </div>
            </div>
            <div class="goldStoreRightBody">
              <div
                class="goldStoreRightBodyBox"
                v-for="(item, index) in goodList"
                :key="index"
                @click="goodsDetail(item)"
              >
                <img
                  :src="require('@/assets/images/one' + index + '.png')"
                  alt=""
                  class="topImg"
                />
                <div class="goldStoreRightBodyBoxmain">
                  <img :src="item.goodsPic" alt="" />
                  <div>
                    <p>{{ item.goodsName }}</p>
                    <p>
                      <span>¥{{ item.goodsDiscountPrice }}</span
                      ><span>¥{{ item.goodsCommodityPrice }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <!-- 猜你喜欢 -->
        <div class="hotspot" id="love" style="margin-bottom: 40px">
          <p class="goldStoreTitle">猜你喜欢</p>
          <div class="hotspotBox">
            <div
              class="hotspotBoxSm"
              v-for="(item, index) in list1"
              :key="index"
              @click="goodsDetail(item)"
            >
              <img :src="item.goodsPic" alt="" />
              <p class="hotspotBoxSmp">{{ item.goodsName }}</p>
              <div class="hotspotBoxPri">
                <div>
                  <span>¥{{ item.goodsDiscountPrice }}</span>
                  <span>¥{{ item.goodsCommodityPrice }}</span>
                </div>
                <div>销量:{{ item.goodsSalesNum || 0 }}</div>
              </div>
            </div>
          </div>
          <Row class="mb20">
            <Page
              class="mt20 mr20 fr"
              size="small"
              show-elevator
              show-sizer
              show-total
              :total="total"
              :page-size="searchForm.pageSize"
              :current.sync="searchForm.page"
              @on-change="homePageYoulikeList"
              @on-page-size-change="
                (pageSize) => {
                  (searchForm.pageSize = pageSize), homePageYoulikeList();
                }
              "
            />
          </Row>
        </div>
      </div>
      <div class="container">
        <!-- 限时活动 -->
        <div class="hotspot" id="love" style="margin-bottom: 40px">
          <p class="goldStoreTitle">限时活动</p>
          <div class="storeActive">
              <div class="storeActiveItem" @click="routeTo('sweepActivities')"><img src="@/assets/images/active/dazhuanpan.png" alt=""></div>
              <div class="storeActiveItem" @click="routeTo('couponActivities')"><img src="@/assets/images/active/youhuiquan.png" alt=""></div>
          </div>
        </div>
      </div>
    </div>
    <goods-list  v-if="$route.path == '/goodsList_tr'" :categoryId="categoryId" class="container" />
     <div v-if="$route.path == '/couponActivities'|| $route.path == '/sweepActivities'">
      <router-view/>
    </div>
  </div>

  <div v-else>
      <router-view/>
  </div>
</template>

<script>
import goodsList from "../Cemetery/components/goodsList";
import GoodsList from "../Cemetery/components/goodsList.vue";
import screen from "./components/screen.vue";
import api from "@/util/api";
export default {
  data() {
    return {
      goodsItem: ["全部排行"],
      randList: [
        { src: require("@/assets/images/one0.png") },
        { src: require("@/assets/images/one1.png") },
        { src: require("@/assets/images/one2.png") },
        { src: require("@/assets/images/one3.png") },
        { src: require("@/assets/images/one4.png") },
        { src: require("@/assets/images/one5.png") },
      ],
      clickCount: 0,
      goodsId: 0,
      goodList: [],
      data: {},
      list: [],
      list1:[],
      total: 0,
      searchForm: {
        page: 1,
        pageSize: 10,
        search: {},
      },
      categoryId:0,
      ipItem:{},
    };
  },
  components: { goodsList, screen, GoodsList },
  watch: {},
  computed: {
    routePath() {
      return this.$route.path;
    },
  },
  mounted() {
    api.homePageinsertIP({},res=>{
      this.ipItem=res.data
      this.homePageGoodList();
      this.search();
      // this.shopAllGoods();
      this.homePageYoulikeList(); //猜你喜欢
      this.$nextTick(()=>{
          this.shopAllGoods();
      })
    })
    
  },
  methods: {
    routeClick(item){
      this.$router.push({path:'/ShopDetail'})
      sessionStorage.setItem('businessId',item.id)
      sessionStorage.setItem('companyId',0)
    },
    search() {
      api.homePageMain({cityId:this.ipItem.cityId,provinceId:this.ipItem.provinceId}, (res) => {
        this.data = res.data;
        this.aLi = res.data.companyData[0];
        let arr = res.data.companyData;
        this.aList = arr.slice(1);
      });
    },
    goodsIdFun(e) {
      this.goodsId = e;
    },
    click_left() {
      console.log(this.list.length);
      if(this.clickCount < this.list.length -4){
        this.clickCount++;
      }
      // this.clickCount > 7 && (this.clickCount = 7);
    },
    click_right() {
      this.clickCount--;
      this.clickCount < 0 && (this.clickCount = 0);
    },
    shopAllGoods() {
      let searchForm = { page: 1, pageSize: 10, search: {} };
      searchForm.search.cityId=this.ipItem.cityId;
      searchForm.search.provinceId=this.ipItem.provinceId;
      api.shopAllGoods(searchForm, (res) => {
        this.list = res.resultList;
      });
    },
    //好物排行
    homePageGoodList() {
      let searchForm = { page: 1, pageSize: 10, search: {} };
      searchForm.search.cityId=this.ipItem.cityId;
      searchForm.search.provinceId=this.ipItem.provinceId;
      api.homePageGoodList(searchForm, (res) => {
        this.goodList = res.resultList.slice(0, 6);
      });
    },
    //猜你喜欢
    homePageYoulikeList() {
      let searchForm = this.searchForm;
      searchForm.search.cityId=this.ipItem.cityId;
      searchForm.search.provinceId=this.ipItem.provinceId;
      api.homePageYoulikeList(searchForm, (res) => {
        this.list1 = res.resultList;
        this.total = res.resultCount || 0;
      });
    },
    // goodsDetail(item) {
    //   sessionStorage.setItem("itemDetails", JSON.stringify(item));
    //   this.$router.push({ path: "/GoodsDetail" });
    // },
    updateInfo(categoryId){
      this.categoryId = categoryId;
    },
  },
};
</script>

<style lang="less" scoped>
.StoreOnline_tr {
  .banner {
    width: 100%;
    // height: 200px;
    background: url("../../../assets/images/banner.png") center no-repeat;
    background-size: cover;
    background-color: aliceblue;
    .banner_title {
      font-size: 36px;
      color: #ffffff;
      line-height: 200px;
      letter-spacing: 2px;
    }
    .allCemetery {
      width: 1120px;
      // height: 215px;
      background: #ffffff;
      box-shadow: 10px 0px 20px 0px rgba(0, 0, 0, 0.2);
      border-radius: 2px 2px 0px 0px;
    }
  }
  .title {
    position: relative;
    width: 1120px;
    height: 65px;
    background: #ffffff;
    font-size: 24px;
    font-weight: 600;
    color: #1a1a1a;
    line-height: 65px;
    letter-spacing: 1px;
    margin-top: 18px;
    padding-left: 40px;
    padding-right: 20px;
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 4px;
      height: 24px;
      background: #299654;
      border-radius: 2px;
      left: 20px;
      top: 20px;
    }
    span {
      font-size: 16px;
      font-weight: 400;
      color: #838383;
      letter-spacing: 1px;
      float: right;
      cursor: pointer;
    }
  }
  .recommendGoods {
    width: calc(100% + 1px);
    position: relative;
    .click_left,
    .click_right {
      position: absolute;
      width: 60px;
      height: 60px;
      background: #ffffff;
      border-radius: 50%;
      font-size: 28px;
      top: 200px;
      color: #c0c0c0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .click_left {
      left: -80px;
    }
    .click_right {
      right: -80px;
    }
    .recommendGoodsList {
      width: 100%;
      overflow: hidden;
      ul {
        transition: all 1s;
        width: 100%;
        display: flex;
        margin-top: 12px;
        li:not(:nth-child(1)){
          margin-left:20px;
        }
        li {
          width: 265px;
          height: 428px;
          background: #ffffff;
          box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
          border-radius: 2px;
          flex-shrink: 0;
          // margin-right: 13px;
          // border:1px solid red;
          img {
            width: 265px;
            height: 328px;
            border-radius: 2px 2px 0px 0px;
          }
          .desc {
            padding: 14px 24px;
            div:nth-child(1) {
              // width: 178px;
              // height: 33px;
              font-size: 24px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #299654;
              line-height: 33px;
              letter-spacing: 1px;
            }
            div:nth-child(2) {
              // width: 90px;
              // height: 33px;
              font-size: 24px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #e64747;
              line-height: 33px;
              letter-spacing: 1px;
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
  .goldStore {
    // margin: 20px auto;
    width: 100%;
    // height: 507px;
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    box-sizing: border-box;
    cursor: pointer;
    .goldStoreTitle {
      height: 33px;
      font-size: 24px;
      font-weight: 400;
      color: #1a1a1a;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
      .iconxiangyou1 {
        font-size: 18px;
        font-weight: 600;
        line-height: 33px;
        color: rgba(41, 150, 84, 1);
        margin: 2px 0 0 8px;
      }
    }
    .goldStoreLeft {
      width: 554px;
      height: 507px;
      background: #ffffff;
      padding: 24px;
      .goldStoreLeftB {
        display: flex;
        flex-wrap: wrap;
      }
      .goldStoreLeftBox:nth-child(2n) {
        margin-left: 14px;
      }
      .goldStoreLeftBox {
        margin-top: 20px;
        p {
          width: 100%;
          height: 22px;
          font-size: 16px;
          font-weight: 400;
          color: #1a1a1a;
          line-height: 22px;
        }
        div {
          display: flex;
          justify-content: space-between;
          margin-top: 8px;
          width: 246px;
          img {
            width: 163px;
            height: 163px;
            border-radius: 2px;
          }
          div {
            height: 163px;
            width: 77px;
            display: flex;
            flex-wrap: wrap;
            img {
              width: 77px;
              height: 77px;
              border-radius: 2px;
            }
            img:nth-child(1) {
              margin-top: -9px;
            }
            img:nth-child(2) {
              margin-top: 2px;
            }
          }
        }
      }
    }
    .goldStoreRight {
      width: 554px;
      height: 507px;
      background: #ffffff;
      padding: 24px;
      margin-left: 12px;
      .goldStoreRightTop {
        display: flex;
        justify-content: space-between;
        div {
          display: flex;
          justify-content: space-between;
          line-height: 40px;
          div {
            // width:70px;
            // line-height:;
            text-align: center;
            margin-right: 20px;
          }
        }
      }
      .goldStoreRightBody {
        height: 400px;
        display: -webkit-flex; /* Safari */
        -webkit-flex-flow: row-reverse wrap; /* Safari 6.1+ */
        display: flex;
        flex-flow: column;
        flex-wrap: wrap;
        .goldStoreRightBodyBox:nth-child(n + 4) {
          margin-left: 12px;
        }
        .goldStoreRightBodyBox {
          width: 247px;
          height: 100px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 32px;
          .topImg {
            width: 38px;
            height: 56px;
          }
          .goldStoreRightBodyBoxmain {
            width: 201px;
            height: 100px;
            display: flex;
            justify-content: space-between;
            img {
              width: 100px;
              height: 100px;
            }
            div {
              p {
                width: 97px;
                height: 40px;
                font-size: 14px;
                font-weight: 400;
                color: #1a1a1a;
                line-height: 20px;
                display: -webkit-box;
                overflow: hidden;
                white-space: normal !important;
                text-overflow: ellipsis;
                word-wrap: break-word;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
              p:nth-child(1):hover {
                color: #299654;
              }
              p:nth-child(2) {
                margin-top: 12px;
                span:nth-child(1) {
                  height: 22px;
                  font-size: 16px;
                  font-weight: 400;
                  color: #e64747;
                  line-height: 22px;
                }
                span:nth-child(2) {
                  height: 14px;
                  font-size: 10px;
                  font-weight: 400;
                  color: #838383;
                  line-height: 14px;
                  text-decoration: line-through;
                }
              }
            }
          }
        }
      }
    }
  }
  .tombstone {
    width: 100%;
    display: flex;
    margin-bottom: 40px;
    .tombstone_left {
      width: 214px;
      img {
        width: 214px;
        height: 335px;
        margin-top: 12px;
      }
    }
    .tombstone_right {
      flex: 1;
      .itemList {
        ul {
          display: flex;
          flex-wrap: wrap;
          li {
            width: 214px;
            height: 335px;
            background: #ffffff;
            border-radius: 2px;
            margin: 12px 0 0;
            margin-left: 12px;
            .li_top {
              width: 214px;
              height: 214px;
              background: #d8d8d8;
              border-radius: 2px 2px 0px 0px;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 177px;
                height: 174px;
              }
            }
            .li_bottom {
              padding: 12px 16px;
              .flowerDesc {
                height: 48px;
                width: 182px;
                font-size: 16px;
                font-weight: 400;
                color: #838383;
                line-height: 24px;
                letter-spacing: 1px;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
              }
              .flowerPrice {
                display: flex;
                margin-top: 20px;
                div:nth-child(1) {
                  height: 33px;
                  font-size: 24px;
                  font-weight: 400;
                  color: #e64747;
                  line-height: 33px;
                  letter-spacing: 1px;
                }
                div:nth-child(2) {
                  height: 17px;
                  font-size: 12px;
                  font-weight: 400;
                  color: #838383;
                  text-decoration: line-through;
                  line-height: 17px;
                  margin-left: 3px;
                  margin-top: 12px;
                }
                div:nth-child(3) {
                  width: 51px;
                  height: 17px;
                  font-size: 12px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #838383;
                  line-height: 17px;
                  margin-left: auto;
                  margin-top: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}

//---
.hotspot {
  width: 1120px;
  // height: 730px;
  background: #ffffff;
  margin: 20px auto 0;
  padding: 24px;
  .goldStoreTitle {
    height: 33px;
    font-size: 24px;
    font-weight: 400;
    color: #1a1a1a;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .iconxiangyou1 {
      font-size: 18px;
      font-weight: 600;
      line-height: 33px;
      color: rgba(41, 150, 84, 1);
      margin: 2px 0 0 8px;
    }
  }
  .hotspotTit {
    display: flex;
    flex-wrap: nowrap;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #c0c0c0;
    line-height: 17px;
    margin-top: 12px;
    span {
      cursor: pointer;
    }
    span:not(:nth-child(1)) {
      margin-left: 20px;
    }
    span:hover {
      color: rgba(41, 150, 84, 1);
    }
  }
  .hotspotBox {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
    .hotspotBoxSm:hover {
      cursor: pointer;
      transition: all 0.2s;
      transform: scale(1.1);
      border: 1px solid rgba(41, 150, 84, 1);
    }
    .hotspotBoxSm {
      padding: 12px 18px;
      width: 214px;
      height: 299px;
      background: #ffffff;
      border: 1px solid #f8f8f8;
      cursor: pointer;
      img {
        width: 176px;
        height: 175px;
        margin-bottom: 8px;
      }
      .hotspotBoxSmp {
        width: 176px;
        height: 48px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4d4d4d;
        line-height: 24px;
        letter-spacing: 1px;
        display: -webkit-box;
        overflow: hidden;
        white-space: normal !important;
        text-overflow: ellipsis;
        word-wrap: break-word;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .hotspotBoxPri {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        div:nth-child(1) {
          span:nth-child(1) {
            height: 33px;
            font-size: 24px;
            font-weight: 400;
            color: #e64747;
            line-height: 33px;
          }
          span:nth-child(2) {
            height: 17px;
            font-size: 12px;
            font-weight: 400;
            color: #838383;
            line-height: 17px;
            text-decoration: line-through;
          }
        }
        div:nth-child(2) {
          height: 17px;
          font-size: 12px;
          font-weight: 400;
          color: #838383;
          line-height: 42px;
        }
      }
    }
  }
  .storeActive{
    display: flex;
    flex-wrap: wrap;
  }
  .storeActiveItem{
      width:214px;
      height:335px;
      margin-top:12px;
      cursor: pointer;
  }
  .storeActiveItem:not(:nth-child(5n+1)){
    margin-left:13px;
  }
}
</style>