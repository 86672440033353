<template>
  <div class="goodsList">
    <div class="itemList">
      <ul>
        <li v-for="(item, idx) in list" @click="goodsDetail(item)">
          <div class="li_top">
            <img :src="item.goodsPic" />
          </div>
          <div class="li_bottom">
            <div class="flowerDesc">
              {{ item.goodsName }}
            </div>
            <div class="flowerPrice">
              <div>¥{{ item.goodsDiscountPrice }}</div>
              <div>¥{{ item.goodsCommodityPrice }}</div>
              <div>销量:{{ item.goodsSalesNum }}</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <Row class="mb20">
      <Page
        class="mt20 mr20 fr"
        size="small"
        show-elevator
        show-sizer
        show-total
        :total="total"
        :page-size="searchForm.pageSize"
        :current.sync="searchForm.page"
        @on-change="search"
        @on-page-size-change="
          (pageSize) => {
            (searchForm.pageSize = pageSize), search();
          }
        "
      />
    </Row>
  </div>
</template>

<script>
import api from "@/util/api";
export default {
  props: ["categoryId"],
  data() {
    return {
      total: 0,
      searchForm: {
        page: 1,
        pageSize: 10,
        search: {},
      },
      list: [],
    };
  },
  mounted() {
    this.search();
  },
  watch: {
    categoryId:{
      deep: true,
      handler: function (newV, oldV) {
        this.search();
      },
    },
  },
  methods: {
    search() {
      let searchForm = this.searchForm;
      this.searchForm.search.goodsCategoryId = this.categoryId || undefined;
      api.shopAllGoods(searchForm, (res) => {
        this.list = res.resultList;
        this.total = res.resultCount;
      });
    },
    // goodsDetail(item) {
    //   sessionStorage.setItem("itemDetails", JSON.stringify(item));
    //   this.$router.push({ path: "/GoodsDetail" });
    // },
  },
};
</script>

<style lang="less" scoped>
.goodsList {
  padding-top: 8px;
  .itemList {
    ul {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      padding: 12px 0 40px;
      li:not(:nth-child(5n+1)) {
        margin-left: 10px;
      }
      li {
        width: 214px;
        height: 339px;
        background: #ffffff;
        border-radius: 2px;
        margin: 12px 0 0;
        cursor: pointer;
        .li_top {
          width: 214px;
          height: 214px;
          background: #d8d8d8;
          border-radius: 2px 2px 0px 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 177px;
            height: 174px;
          }
        }
        .li_bottom {
          padding: 12px 16px;
          .flowerDesc {
            height: 48px;
            width: 182px;
            font-size: 16px;
            font-weight: 400;
            color: #838383;
            line-height: 24px;
            letter-spacing: 1px;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
          .flowerPrice {
            display: flex;
            margin-top: 20px;
            div:nth-child(1) {
              height: 33px;
              font-size: 24px;
              font-weight: 400;
              color: #e64747;
              line-height: 33px;
              letter-spacing: 1px;
            }
            div:nth-child(2) {
              height: 17px;
              font-size: 12px;
              font-weight: 400;
              color: #838383;
              text-decoration: line-through;
              line-height: 17px;
              margin-left: 3px;
              margin-top: 12px;
            }
            div:nth-child(3) {
              width: 60px;
              height: 17px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #838383;
              line-height: 17px;
              margin-left: auto;
              margin-top: 12px;
            }
          }
        }
      }
    }
  }
  .page {
    display: flex;
  }
  /deep/.ivu-page {
    margin-bottom: 40px;
    margin-left: auto;
  }
}
</style>